var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.itemData
        ? _c(
            "b-row",
            { staticClass: "content-header" },
            [
              _c(
                "b-col",
                {
                  staticClass: "content-header-left mb-2",
                  attrs: { cols: "12", md: "9" }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "breadcrumbs-top" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "content-header-title float-left pr-1 mb-0"
                          },
                          [_vm._v(" Submissions ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "breadcrumb-wrapper" },
                          [
                            _c(
                              "b-breadcrumb",
                              [
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { to: "/apps/reports/type" } },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "align-text-top",
                                      attrs: { icon: "HomeIcon", size: "16" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { active: "" } },
                                  [_vm._v(" Send Submission ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$can("read", _vm.abilityRequired) ||
      _vm.$can("create", _vm.abilityRequired)
        ? _c(
            "b-card",
            [
              _c(
                "b-card-body",
                [
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var handleSubmit = ref.handleSubmit
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.validateForm)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Submission Type ",
                                                "label-for": "submission_type"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "submission_type"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.submissionTypeOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "submission_type",
                                                              rules: "required"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleSelectType
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .submission_type,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "submission_type",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.submission_type"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.showPrivacyMessage
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c("b-col", { attrs: { md: "12" } }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "alert alert-info mt-0 pt-1 pb-1 pl-1 pr-1",
                                                attrs: {
                                                  id: "noNameSubmit",
                                                  role: "alert"
                                                }
                                              },
                                              [
                                                _c("h4", [
                                                  _vm._v("Please Note:")
                                                ]),
                                                _vm._v(
                                                  " This submission will be anonymous and will not be saved in this system."
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " This submission will be send anonymously via email to the appropriate authority and will be dealt with in a sensitive manner. "
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Message ",
                                                "label-for": "message"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "message",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-textarea",
                                                            {
                                                              attrs: {
                                                                id: "message",
                                                                rows: "3",
                                                                "max-rows": "8"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemData
                                                                    .message,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.itemData,
                                                                    "message",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "itemData.message"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-3" },
                                        [
                                          _vm.$can(
                                            "create",
                                            _vm.abilityRequired
                                          )
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "3" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                      attrs: {
                                                        variant: "primary",
                                                        block: "",
                                                        type: "submit",
                                                        disabled:
                                                          _vm.preventDoubleClick
                                                      }
                                                    },
                                                    [_vm._v(" Submit ")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2624361128
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }